import * as Sentry from "@sentry/react";

window.Sentry = Sentry;

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Session Replay
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_SESSIONS_SAMPLE_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_SESSIONS_ERROR_RATE // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
