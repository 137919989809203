import axios from 'axios';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import './echo.js';
import './sentry.js';


const domNode = document.getElementById('loader');
const appName = 'Webfluential';


createInertiaApp({
    title: (title) => title ? `${title} - ${appName}` : appName,
    resolve: async (name) => {

        return resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'))
    },
    setup({ el, App, props })
    {
        createRoot(el).render(<App {...props}  />);
    },
    progress: {
        // color: '#80C3C1',
        color: '#fde68a',
        delay: 10,
        includeCSS: true,
        showSpinner: true,
    },
}).then(() => {
    // await new Promise(resolve => setTimeout(resolve, 1000));
    domNode.remove();
});
